import { state } from '@angular/animations';
import { environment } from '@environments/environment';
import { ISocialCollectionState, ISocialState } from '@models';
import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { IComment } from '@shared/models/comment-model';
import { SocialPostModel } from '@shared/models/social-post.model';
import * as socialActions from '@store/actions/social';
import * as userActions from '@store/actions/user';

export const NAMESPACE = 'social';

export const adapter = createEntityAdapter<SocialPostModel>({
  selectId: (entity: SocialPostModel) => entity.Id,
});

export function getSocialCollectionId(followedOnly: boolean) {
  return followedOnly ? 'following' : 'all';
}
export function getSocialUserCollectionId(userId: string) {
  return 'user_' + userId;
}
export function getSocialProductCollectionId(productId: string) {
  return 'product_' + productId;
}

export function getSocialCollectionInitialState(): ISocialCollectionState {
  return adapter.getInitialState({
    isLoading: false,
    showMore: false,
    ids: null,
  });
}

export const initialState = {
  collections: {},
  entities: {},
  isLoading: {},
  isLikeLoading: {},
  isCommentLoading: {},
  isCommentsLoading: {},
  isFollowLoading: {},
};

export const SocialReducer = createReducer<ISocialState>(
  initialState,
  on(socialActions.SocialPostsGarbageCollection, (state) => {
    const uniqueExistingIds = Object.keys(state.collections).reduce(
      (acc, collectionId) => {
        const collection = state.collections[collectionId];
        // collection.ids can be null if called after a cleanup
        if (!collection || !collection.ids) return acc;
        return [...new Set([...acc, ...collection.ids])];
      },
      [] as number[]
    );
    return {
      ...state,
      entities: Object.keys(state.entities).reduce((acc, postId) => {
        if (uniqueExistingIds.includes(Number(postId))) {
          return {
            ...acc,
            [postId]: state.entities[postId],
          };
        }
        return acc;
      }, {}),
    };
  }),
  on(socialActions.SocialPostsToTop, (state, { collectionId }) => {
    const collectionState =
      state.collections[collectionId] || getSocialCollectionInitialState();
    return {
      ...state,
      collections: {
        ...state.collections,
        [collectionId]: {
          ...collectionState,
          ids: collectionState.ids.slice(0, environment.pageLimit || 20),
          showMore: true,
        },
      },
    };
  }),
  on(
    socialActions.SocialPostsRequest,
    (state, { followedOnly }): ISocialState => {
      const collectionId = getSocialCollectionId(followedOnly);
      const collectionState =
        state.collections[collectionId] || getSocialCollectionInitialState();
      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...collectionState,
            isLoading: true,
          },
        },
      };
    }
  ),
  on(
    socialActions.SocialUserPostsRequest,
    (state, { userId }): ISocialState => {
      const collectionId = getSocialUserCollectionId(userId);
      const collectionState =
        state.collections[collectionId] || getSocialCollectionInitialState();
      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...collectionState,
            isLoading: true,
          },
        },
      };
    }
  ),
  on(
    socialActions.SocialProductPostsRequest,
    (state, { productId }): ISocialState => {
      const collectionId = getSocialProductCollectionId(productId);
      const collectionState =
        state.collections[collectionId] || getSocialCollectionInitialState();
      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionId]: {
            ...collectionState,
            isLoading: true,
          },
        },
      };
    }
  ),
  on(
    socialActions.SocialPostsSuccess,
    (state, { collectionId, take, posts, skip }): ISocialState => {
      const collectionState =
        state.collections[collectionId] || getSocialCollectionInitialState();
      const shouldReset = skip === 0;
      const { entities } = adapter.upsertMany(posts, {
        entities: state.entities,
        ids: [],
      });
      return {
        ...state,
        entities,
        collections: {
          ...state.collections,
          [collectionId]: {
            ids: shouldReset
              ? posts.map((p) => p.Id)
              : (collectionState.ids as number[]).concat(
                  posts.map((p) => p.Id)
                ),
            entities: {},
            isLoading: false,
            showMore: posts.length === take,
          },
        },
      };
    }
  ),
  on(socialActions.SocialPostRequest, (state, { postId }) => {
    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        [postId]: true,
      },
    };
  }),
  on(socialActions.SocialPostSuccess, (state, { postId, post }) => {
    const { entities } = adapter.upsertOne(post, {
      entities: state.entities,
      ids: [],
    });
    return {
      ...state,
      entities,
      isLoading: {
        ...state.isLoading,
        [postId]: false,
      },
    };
  }),
  on(socialActions.SocialPostLikeToggleRequest, (state, { postId }) => {
    return {
      ...state,
      isLikeLoading: {
        ...state.isLikeLoading,
        [postId]: true,
      },
    };
  }),
  on(socialActions.SocialPostLoadCommentsRequest, (state, { postId }) => {
    return {
      ...state,
      isCommentsLoading: {
        ...state.isCommentsLoading,
        [postId]: true,
      },
    };
  }),
  on(
    socialActions.SocialPostLoadCommentsSuccess,
    (state, { postId, comments }) => {
      const post = state.entities[postId];
      const { entities } = adapter.updateOne(
        {
          id: postId,
          changes: {
            TopComments: comments,
          },
        },
        {
          entities: state.entities,
          ids: [],
        }
      );
      return {
        ...state,
        entities,
        isCommentsLoading: {
          ...state.isCommentsLoading,
          [postId]: false,
        },
      };
    }
  ),
  on(socialActions.SocialPostLoadCommentsError, (state, { postId }) => {
    return {
      ...state,
      isCommentsLoading: {
        ...state.isCommentsLoading,
        [postId]: false,
      },
    };
  }),
  on(socialActions.SocialPostLikeToggleSuccess, (state, { postId, like }) => {
    const post = state.entities[postId];
    const { entities } = adapter.updateOne(
      {
        id: postId,
        changes: {
          Liked: like !== null,
          // the nbr of likes updates will come from the socket via SOCIAL_POST_EDIT_SUCCESS
          // Likes: post.Likes + (like ? 1 : -1),
        },
      },
      {
        entities: state.entities,
        ids: [],
      }
    );
    return {
      ...state,
      entities,
      isLikeLoading: {
        ...state.isLikeLoading,
        [postId]: false,
      },
    };
  }),
  on(socialActions.SocialPostLikeToggleError, (state, { postId }) => {
    return {
      ...state,
      isLikeLoading: {
        ...state.isLikeLoading,
        [postId]: false,
      },
    };
  }),
  on(socialActions.SocialPostFollowToggleRequest, (state, { userId }) => {
    return {
      ...state,
      isFollowLoading: {
        ...state.isFollowLoading,
        [userId]: true,
      },
    };
  }),
  on(
    socialActions.SocialPostFollowToggleSuccess,
    (state, { userId, userType, isFollowed }) => {
      const postsToUpdate = Object.keys(state.entities)
        .filter((postId) => {
          const post = state.entities[postId];
          if (userType === 'users') {
            return post.User.UserId === userId;
          } else {
            return post.User.UUID === userId;
          }
        })
        .map((postId) => state.entities[postId]);

      const { entities } = adapter.updateMany(
        postsToUpdate.map((post) => ({
          id: post.Id,
          changes: {
            User: {
              ...post.User,
              Followed: !isFollowed,
            },
          },
        })),
        {
          entities: state.entities,
          ids: [],
        }
      );
      return {
        ...state,
        entities,
        isFollowLoading: {
          ...state.isFollowLoading,
          [userId]: false,
        },
      };
    }
  ),
  on(socialActions.SocialPostFollowToggleError, (state, { userId }) => {
    return {
      ...state,
      isFollowLoading: {
        ...state.isFollowLoading,
        [userId]: false,
      },
    };
  }),
  on(
    socialActions.SocialPostAddCommentRequest,
    socialActions.SocialPostEditCommentRequest,
    socialActions.SocialPostRemoveCommentRequest,
    (state, { postId }) => {
      return {
        ...state,
        isCommentLoading: {
          ...state.isCommentLoading,
          [postId]: true,
        },
      };
    }
  ),
  on(
    socialActions.SocialPostAddCommentSuccess,
    (state, { postId, comment }) => {
      const post = state.entities[postId];
      // a post can be undefined if the comment is pushed from the socket
      if (!post) return state;
      // can happen when the comment is pushed from the socket
      // from the same author
      const isCommentAlreadyExists = post.TopComments.map((c) => c.Id).includes(
        comment.Id
      );
      if (isCommentAlreadyExists) return state;
      if (!post) return state;
      const { entities } = adapter.updateOne(
        {
          id: postId,
          changes: {
            Comments: post.Comments + 1,
            TopComments: [comment, ...post.TopComments],
          },
        },
        {
          entities: state.entities,
          ids: [],
        }
      );
      return {
        ...state,
        entities,
        isCommentLoading: {
          ...state.isCommentLoading,
          [postId]: false,
        },
      };
    }
  ),
  on(
    socialActions.SocialPostEditCommentSuccess,
    (state, { postId, commentId, comment }) => {
      const post = state.entities[postId];
      // a post can be undefined if the comment is pushed from the socket
      if (!post) return state;
      const { entities } = adapter.updateOne(
        {
          id: postId,
          changes: {
            TopComments: post.TopComments.map((c) =>
              c.Id === commentId ? comment : c
            ),
          },
        },
        {
          entities: state.entities,
          ids: [],
        }
      );
      return {
        ...state,
        entities,
        isCommentLoading: {
          ...state.isCommentLoading,
          [postId]: false,
        },
      };
    }
  ),
  on(
    socialActions.SocialPostRemoveCommentSuccess,
    (state, { postId, commentId }) => {
      // if postId is not provided, we need to find the post that contains the comment
      // it happens when a comment is removed from the socket
      // all we have is the commentId
      let postIdToRemove = postId;
      if (!postIdToRemove) {
        for (const postId in state.entities) {
          const post = state.entities[postId];
          if (!post) continue;
          if (post.TopComments.map((e) => e.Id).includes(commentId)) {
            postIdToRemove = post.Id;
            break;
          }
        }
      }
      const post = state.entities[postIdToRemove];
      // a post can be undefined if the comment is pushed from the socket
      if (!post) return state;
      const { entities } = adapter.updateOne(
        {
          id: postIdToRemove,
          changes: {
            Comments: post.Comments - 1,
            TopComments: post.TopComments.filter((c) => c.Id !== commentId),
          },
        },
        {
          entities: state.entities,
          ids: [],
        }
      );
      return {
        ...state,
        entities,
        isCommentLoading: {
          ...state.isCommentLoading,
          [postIdToRemove]: false,
        },
      };
    }
  ),
  on(
    socialActions.SocialPostAddCommentError,
    socialActions.SocialPostEditCommentError,
    socialActions.SocialPostRemoveCommentError,
    (state, { postId }) => {
      return {
        ...state,
        isCommentLoading: {
          ...state.isCommentLoading,
          [postId]: false,
        },
      };
    }
  ),
  on(socialActions.SocialPostCreateSuccess, (state, { post }) => {
    const collectionState =
      state.collections['all'] || getSocialCollectionInitialState();

    // postAlreadyExists=true when the post is pushed from the socket with the same author
    const postAlreadyExists = state.entities[post.Id] !== undefined;
    if (postAlreadyExists) return state;

    // if no feed was loaded yet, we don't need to update the state
    // the user will see the new post when he goes to the feed
    if (!collectionState.ids?.length) {
      return state;
    }

    const { entities } = adapter.addOne(post, {
      entities: state.entities,
      ids: [],
    });

    return {
      ...state,
      entities,
      collections: {
        ...state.collections,
        ['all']: {
          ...collectionState,
          ids: [post.Id, ...(collectionState.ids as number[])],
          entities: {},
        },
      },
    };
  }),
  on(socialActions.SocialPostsCleanup, (state, { collectionId }) => {
    return {
      ...state,
      collections: {
        ...state.collections,
        [collectionId]: undefined,
      },
    };
  }),
  on(socialActions.SocialPostEditSuccess, (state, { postId, post }) => {
    // a post can be undefined if from the socket
    if (!state.entities[postId]) return state;
    const { TopComments: existingTopComments } = state.entities[postId];
    const { TopComments: newTopComments } = post;

    const { entities } = adapter.updateOne(
      {
        id: postId,
        changes: {
          ...post,
          TopComments: mergeComments(
            existingTopComments || [],
            newTopComments || []
          ),
        },
      },
      { entities: state.entities, ids: [] }
    );

    return {
      ...state,
      entities,
    };
  }),
  on(socialActions.SocialPostRemoveSuccess, (state, { postId }) => {
    // a post can be undefined if from the socket
    if (!state.entities[postId]) return state;
    const { entities } = adapter.removeOne(postId, {
      entities: state.entities,
      ids: [],
    });

    return {
      ...state,
      entities,
      collections: Object.keys(state.collections).reduce(
        (acc, collectionId) => ({
          ...acc,
          [collectionId]: {
            ...state.collections[collectionId],
            ids: state.collections[collectionId].ids.filter(
              (id) => id !== postId
            ),
          },
        }),
        {}
      ),
    };
  }),
  on(socialActions.SocialBlockUserSuccess, (state, { userId, userType }) => {
    const postIdsToRemove = Object.keys(state.entities).filter((postId) => {
      const post = state.entities[postId];
      if (userType === 'guests') {
        return post.User.UUID === userId;
      } else {
        return post.User.UserId === userId;
      }
    });

    // remove posts from blocked user
    const { entities: postEntities } = adapter.removeMany(postIdsToRemove, {
      entities: state.entities,
      ids: [],
    });

    // remove comments from blocked user
    const { entities } = adapter.map(
      (entity) => {
        return {
          ...entity,
          TopComments: entity.TopComments.filter((comment) => {
            if (userType === 'guests') {
              return comment.User.UUID !== userId;
            } else {
              return comment.User.UserId !== userId;
            }
          }),
        };
      },
      {
        entities: postEntities,
        ids: Object.keys(state.entities),
      }
    );

    return {
      ...state,
      entities,
      collections: Object.keys(state.collections).reduce(
        (acc, collectionId) => ({
          ...acc,
          [collectionId]: {
            ...state.collections[collectionId],
            ids: state.collections[collectionId].ids.filter(
              (id) => !postIdsToRemove.includes(String(id))
            ),
          },
        }),
        {}
      ),
    };
  }),
  on(userActions.LogoutSuccess, userActions.LoginSuccess, () => initialState)
);

export default SocialReducer;

function mergeComments(existing: IComment[], newComments: IComment[]) {
  // Create a map to hold existing comments by their Id for quick lookup
  const existingCommentsMap = new Map<number, IComment>();
  for (const comment of existing) {
    existingCommentsMap.set(comment.Id, comment);
  }

  // Iterate through new comments to update or add them to the existing list
  for (const newComment of newComments) {
    existingCommentsMap.set(newComment.Id, newComment);
  }

  // Convert the map back to an array
  return Array.from(existingCommentsMap.values()).sort((a, b) => {
    return new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime();
  });
}
